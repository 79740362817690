

























import Vue from "vue";
export default Vue.extend({
  props: ["value", "label", "valueLabel"],
  data() {
    return {
      items: [],
    };
  },
  watch: {
    items: {
      handler() {
        this.updateValue();
      },
      deep: true,
    },
  },
  computed: {
    activeItems() {
      return this.items.filter((item) => !item.disabled);
    },
  },
  methods: {
    initialize() {
      if (this.value) {
        this.items = JSON.parse(this.value);
      }
    },
    removeItem(index) {
      this.$set(this.items[index], "disabled", true);
    },
    updateValue() {
      const value = JSON.stringify(this.activeItems);
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
  created() {
    this.initialize();
  },
});
