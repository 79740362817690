






















import Vue from "vue";
export default Vue.extend({
  props: ["value", "valueUnit"],
  data() {
    return {
      items: [],
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.items = JSON.parse(val);
        }
      },
      immediate: true,
    },
  },
});
