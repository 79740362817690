















































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import RangeBaseInput from "@/components/RangeBaseInput.vue";
import RangeBaseDisplay from "@/components/RangeBaseDisplay.vue";

export default Vue.extend({
  components: { RangeBaseInput, RangeBaseDisplay },
  data() {
    return {
      dialog: false,
      form: false,
      dialogDelete: false,
      userManager,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: "Tên mẫu", value: "name" },
        { text: "Mô tả", value: "description" },
        { text: "Bảng giá tiêu chuẩn", value: "is_standard" },
        { text: "Giá cân", value: "fee_per_weight_items_payload" },
        { text: "Giá khối", value: "fee_per_volume_items_payload" },
        { text: "Ủy thác", value: "fee_trust_items_payload" },
        !userManager.getImpersonateUserInfo() && {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
        },
      ],
      options: {},
      loading: false,
      data: [],
      dataCount: 0,
      search: "",
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tạo mẫu" : this.$t("EDIT");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.loading = true;
      const { items, count } = await apiClient.pricingItemGetAll({
        options: this.options,
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.pricingItemDelete(this.editedItem);
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let result;
      if (this.editedIndex > -1) {
        result = await apiClient.pricingItemUpdate(this.editedItem);
      } else {
        result = await apiClient.pricingItemCreate(this.editedItem);
      }
      if (result) {
        this.close();
        await this.initialize();
      }
    },
  },
});
